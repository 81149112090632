button.profile {
    all: inherit;
    color: #4b8fdf;
}

button.profile:hover {
    box-shadow: 0 1px 0 0 rgba(162, 197, 238, 1);
    cursor: pointer;
    color: #23527c;
}

button.profile:disabled {
    color: #333;
    pointer-events: none;
}

@import (css) "fonts.css";
@import (css) "../fonts/golostext/golostext.css";
@import "./smart-grid.less";
@import (css) "bootstrap.css";
@import (css) "../../../../node_modules/magnific-popup/dist/magnific-popup.css";
@import (css) "../../../../node_modules/tinymce/skins/ui/oxide/skin.css";
@import (css) "../../../../node_modules/tinymce/skins/ui/oxide/content.css";
@import (css) "./animations.css";
@import (css) "all.css";
@import (css) "rc.css";
@import (css) "c3.css";
@import (css) "./grid.css";
@import (css) "../../../../node_modules/angular-growl-v2/build/angular-growl.css";
@import (css) "../../../../node_modules/font-awesome/css/font-awesome.css";
@import (css) "checkbox-slider.css";
@import (css) "./checkbox.css";
@import (css) "flags.css";
@import (css) "./tagsInput.css";
@import (css) "./table.css";
@import (css) "./icons.css";
@import (css) "./select.css";
@import (css) "./sectionHeader.css";
@import (css) "./chartStyle.css";
@import (css) "../../../../node_modules/angular-bootstrap-colorpicker/css/colorpicker.css";
@import (css) "./theme-variables.css";
@import (css) "./brouserError.css";
@import "../js/React/style/reactStyle.less";

a {
  cursor: pointer;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.form-group {
  min-height: 40px;
}

.process-container {
  margin-left: 15px;
  line-height: 28px;
  display: inline-block;
  text-align: left;
  min-width: calc(100% - 240px);
  float: right;

  &.large {
    width: 600px;
  }

  &.small {
    width: 315px;
  }

  &.text {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }

  min-height: 33px;

  & > div {
    display: inline-block;
  }

  .process-text {
    float: right;
    text-align: left;
    width: ~"calc(100% - 40px)";
  }
}

.inline-items-group {
  display: block;

  .inline-item {
    display: inline-block;
    margin-right: 15px;
    vertical-align: top;
    height: 40px;

    &:last-child {
      margin-right: 0;
    }

    &.inline-item-input {
      min-width: 300px;
      max-width: 300px;

      .input-group-btn {
        .btn {
          margin-left: -2px;
          width: 13px;
          height: 40px;

          button {
            outline: 0;
            padding: 0;
            top: 13px;
            position: absolute;
          }
        }
      }
    }
  }
}

.languages {
  text-align: left;
  margin: 0 auto;
  width: 80px;
  padding: 10px 0 0 0;
  margin-top: -10px;

  ul {
    li {
      .lang-flag {
        margin-top: 3px;
      }
    }
  }

  & > a {
    text-decoration: none;
    box-shadow: none;
    display: table;
    width: 80px;
  }

  .lang-flag {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
    margin-top: 2px;

    &.ru {
      background-image: url("../images/ru.svg");
    }

    &.en {
      background-image: url("../images/en.svg");
    }

    &.it {
      background-image: url("../images/it.svg");
    }

    &.de {
      background-image: url("../images/de.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-y: 4px;
    }
  }

  .fa {
    margin-left: 3px;
  }

  &:hover {
    .languages-list {
      &.languages-login {
        top: -70px;
      }

      width: 120px !important;
      max-width: 120px;
      overflow: hidden;
      min-width: 120px;
      margin: 0 auto;
      display: table;
      position: absolute !important;
      left: calc(50% - 60px);
      top: -100px;
    }
  }

  .languages-list {
    position: relative !important;
    display: none;
    margin-top: -115px;

    a {
      display: block;
      width: 100%;
      text-decoration: none;
      box-shadow: none;

      &:hover {
        background-color: #428bca;
        color: #ffffff;
      }
    }
  }
}

.dasboard-checkbox {
  position: absolute;
  right: -30px;
  margin: 15px 0 0 0;
  @media (max-width: 1020px) {
    right: 20px;
  }
}

.info-board {
  width: 1020px;
  position: relative;
  background-color: #ffffff;

  .info-board_arrow-left,
  .info-board_arrow-right {
    position: absolute;
    height: 100px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.3;
  }

  .info-board_arrow-left {
    top: 100px;
    left: -20px;
    background-image: url("../images/left-arrow.svg");
    background-position: left;
  }

  .info-board_arrow-right {
    top: 100px;
    right: -20px;
    background-image: url("../images/right-arrow.svg");
    background-position: right;
  }

  .info-board__col {
    width: 25%;
    padding: 0px 45px;
    margin: 0;
    height: auto;

    &:nth-child(3),
    &:nth-child(4) {
      padding-left: 60px;
    }

    &:nth-child(5) {
      padding-left: 70px;
      padding-right: 0;
    }

    @media (max-width: 1020px) {
      width: 50%;
      text-align: center;
      padding-bottom: 1.86rem;
      & > div {
        margin: 0 auto;
      }

      &:last-child {
        padding-bottom: 0px;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &.v1 {
    padding-bottom: 20px;

    .info-board_arrow-left,
    .info-board_arrow-right {
      top: 50px;
    }

    .info-board__col {
      &:nth-child(5) {
        padding-left: 100px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        padding-left: 65px;
      }
    }
  }

  &.v2 {
    &:hover {
      .info-board__col {
        -webkit-filter: grayscale(100%);

        &:hover {
          -webkit-filter: none;
        }
      }
    }

    .info-board__col {
      min-height: 250px;
      cursor: default;

      &:hover {
        p {
          &:after {
            opacity: 0;
          }
        }

        a {
          color: #4b8fdf;
        }
      }

      &:not(.disabled) > p {
        cursor: pointer;

        &:hover {
          &:after {
            opacity: 0.3;
          }
        }
      }
    }

    p {
      margin-bottom: 10px;
    }

    a {
      color: #555;
    }

    .skills-counter,
    .knowledge-counter,
    .rating-counter,
    .vuln-counter {
      position: relative;
      width: 155px;
      height: 155px;

      canvas {
        &.cursor-pointer {
          cursor: pointer;
        }

        &.loading {
          animation: ~"1s linear 0s normal none infinite running rot";
          -webkit-animation: ~"1s linear 0s normal none infinite running rot";
        }
      }

      .v2-percent {
        text-align: center;
        font-size: 50px;
        line-height: 50px;
        margin: -105px 20px 0 20px;
        border-radius: 40px;

        &.index-0 {
          color: #c3c3c3;
        }

        &.index-1 {
          color: #f6a622;
        }

        &.index-2 {
          color: #5db75c;
        }

        &.index-4 {
          color: #bd0ce0;
        }

        div {
          display: inline-block;

          &:not(.percent) {
            letter-spacing: 0;
            margin-right: -10px;
          }

          &.percent {
            font-size: 24px;
          }

          &.less {
            font-size: 25px;
            vertical-align: top;
          }
        }
      }
    }

    .knowledge-counter {
      .v2-percent {
        &.index-1 {
          color: #f6a622;
        }

        &.index-2 {
          color: #5db75c;
        }

        &.index-3 {
          color: #bd0ce0;
        }
      }
    }

    .skills-counter {
      .v2-percent {
        &.index-1 {
          color: #5db75c;
        }

        &.index-2 {
          color: #f6a622;
        }

        &.index-3 {
          color: #d95250;
        }
      }
    }

    .vuln-counter {
      .v2-percent {
        &.index-1 {
          color: #5db75c;
        }

        &.index-2 {
          color: #bd0ce0;
        }
      }
    }

    .rating-counter {
      padding-top: 25px;

      .green {
        background-color: #5db75c;
      }

      .red {
        background-color: #d95250;
      }

      & > div {
        display: block;
        width: 100%;
        height: 100px;
        color: #ffffff;
        line-height: 100px;
        font-size: 50px;
        text-align: center;
        word-spacing: -5px;

        & > nobr {
          cursor: pointer;
          display: block;
          line-height: 25px;
          font-size: 25px;
          position: absolute;
          padding: 5px 10px;
          top: 5px;
          right: -15px;
          -webkit-box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.5);
          box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.5);
          word-spacing: -5px;

          .rating-up,
          .rating-down {
            display: inline-block;
            width: 10px;
            height: 13px;
            margin: 0 0 0 3px;
          }

          .rating-up {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid #ffffff;
            height: 17px;
            vertical-align: top;
          }

          .rating-down {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #ffffff;
          }
        }
      }
    }

    .skills,
    .knowledge,
    .rating,
    .vuln {
      &:hover {
        .static-desc {
          nobr {
            &.last-link {
              opacity: 1;
            }
          }
        }
      }
    }

    .static-desc {
      margin-top: 10px;
      width: 200px;

      nobr {
        display: block;

        &.last-link {
          margin-top: 7.5px;
          opacity: 0;
        }
      }

      & > div {
        line-height: 18px;
      }

      a {
        line-height: 18px;
        font-size: 15px;
        display: inline;

        &:hover {
          box-shadow: 0px 1px 0px 0px rgba(162, 197, 238, 1);
          color: #1e5ca6;
        }
      }
    }
  }
}

.info-board-small {
  width: 100%;

  .info-board-small__col {
    width: 25%;
    margin-left: 0;
    line-height: 24px;

    &.disabled {
      color: #555;

      &:hover {
        span.title {
          border-bottom: 0;
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      text-align: center;
    }
  }

  .checkbox-slider-sm {
    width: 20px;
    display: block;
    right: -50px;
    top: 50px;
    position: absolute;
  }

  &.v2 {
    .v2-percent {
      &.targets {
        &.index-0 {
          color: #c3c3c3 !important;
        }

        &.index-1 {
          color: #f6a622 !important;
        }

        &.index-2 {
          color: #5db75c !important;
        }

        &.index-3 {
          color: #bd0ce0 !important;
        }
      }

      &.attacks {
        &.index-0 {
          color: #c3c3c3 !important;
        }

        &.index-1 {
          color: #5db75c !important;
        }

        &.index-2 {
          color: #f6a622 !important;
        }

        &.index-3 {
          color: #d95250 !important;
        }

        &.index-4 {
          color: #bd0ce0 !important;
        }
      }

      &.risks {
        &.index-0 {
          color: #c3c3c3 !important;
        }

        &.index-1 {
          color: #5db75c !important;
        }

        &.index-2 {
          color: #bd0ce0 !important;
        }
      }
    }

    .rating {
      width: auto;
      display: inline-block;
      font-weight: initial;

      .rating--indicator {
        display: inline-block;
        font-weight: initial;
      }

      .rating--change {
        font-weight: initial;
        display: inline-block;
        float: right;
        margin-top: -10px;
        margin-left: 5px;
      }
    }
  }
}

.search-filter-container {
  padding: 0 !important;

  .search-filter {
    position: relative;
    width: 40px;
    margin-top: -10px;
    margin-left: -10px;

    > .ico-filter {
      background-image: url("../images/ico-filter.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      font-weight: normal;
      display: inline-block;
      padding: 20px;
      margin: -20px 0px -20px 0px;
      cursor: pointer;
    }

    &:hover {
      > ul {
        display: block;
      }
    }

    &:hover {
      > .search-filter-spacer {
        display: block;
      }
    }

    > .search-filter-spacer {
      z-index: 1;
      width: auto !important;
      min-width: 70px;
      top: 30px;
      height: 25px;
      left: -10px;
      display: none;
      position: absolute;

      &:hover {
        & ~ ul {
          display: block;
        }
      }
    }

    > ul {
      width: auto !important;
      min-width: 100%;
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 1000;
      float: left;
      padding: 5px 0;
      margin: 2px 0 0 -10px;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-color: #fff;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      overflow-y: auto;
      max-height: 200px;

      &:hover {
        display: block;
      }

      > li {
        padding-right: 0 !important;
        width: 100%;

        > div {
          width: 100%;

          &:hover {
            background-color: #f5f5f5;
          }

          &.divider {
            height: 1px;
            margin: 9px 0;
            overflow: hidden;
            background-color: #e5e5e5;
          }

          &.active {
            > span {
              color: #fff;
              text-decoration: none;
              outline: 0;
              background-color: #428bca;
            }
          }

          > span {
            cursor: pointer;
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: 400;
            line-height: 1.42857143;
            color: #333;
            white-space: nowrap;

            .sortorder {
              position: relative;

              &:after {
                line-height: 1;
                padding-left: 0;
                position: relative;
                top: 0;
                right: 0;
                color: red;
              }

              &.reverse {
                &:after {
                  color: green;
                }
              }
            }
          }
        }
      }
    }
  }
}

.box-attachment {
  display: block;
  line-height: 38px;
  float: left;
  padding-right: 10px;
  cursor: pointer;
  white-space: nowrap;

  .attach-icon {
    display: block;
    width: 19px;
    height: 38px;
    background-image: url("../images/attachments/detached.svg");
    background-repeat: no-repeat;

    &.checked {
      background-image: url("../images/attachments/attached.svg");
    }

    &.lnk {
      background-image: url("../images/attachments/lnk-ico.svg");
    }

    &.file-lnk {
      background-image: url("../images/attachments/file-lnk-ico.svg");
    }
  }
}

.attachment-actions {
  line-height: 38px;
  padding: 0;
  width: 260px;
  text-align: right;

  .btn-group {
    & + .btn {
      margin-left: 10px;
      min-height: 40px;
      line-height: 25px;
    }
  }
}

.attachment-left {
  padding-right: 0;
  width: 200px;

  .box-attachment {
    &:last-child {
      padding-right: 0;
    }
  }
}

.attachment-items {
  padding-left: 0;
  width: 400px;
  float: right;
}

.attachment-extensions {
  z-index: 1;
  position: absolute;
  left: 0px;
  padding: 10px;
  margin: -10px;

  .ext {
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    background-position: center;
    cursor: pointer;
    background-size: 30px;
    background-color: #ffffff;
  }

  .ext-word {
    background-image: url("../images/attachments/word-icon.svg");
  }

  .ext-excel {
    background-image: url("../images/attachments/excel-icon.svg");
  }

  .ext-powerpoint {
    background-image: url("../images/attachments/ppt-icon.svg");
  }

  .ext-pdf {
    background-image: url("../images/attachments/pdf-icon.svg");
  }

  .ext-text {
    background-image: url("../images/attachments/js-icon.svg");
  }

  .ext-richtext {
    background-image: url("../images/attachments/rtf-icon.svg");
  }

  .ext-html {
    background-image: url("../images/attachments/html-icon.svg");
  }

  .ext-ics {
    background-image: url("../images/attachments/ics-icon.svg");
  }

  .extensions-list {
    display: none;

    &.disabled {
      display: none !important;
    }
  }

  &:hover {
    .extensions-list {
      display: block;
    }
  }
}

.error-container {
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  height: auto;
  margin: 85px 0;

  .logo-container {
    display: block;
    height: 90px;
    width: 100%;

    .logo {
      display: block;
      float: left;
      background-size: cover;
      background-repeat: no-repeat;
      width: 75px;
      height: 90px;
      background-image: ~'url("/attack.svg")';
      background-repeat: no-repeat;
      margin-left: -35px;
    }

    .separator {
      display: none;
    }

    .slogan {
      display: none;
    }
  }

  .message-container {
    width: 100%;
    display: block;
    margin-top: 20px;

    h1 {
      font-size: 31px;
    }

    .message {
      margin-top: 45px;
      width: 100%;
      font-size: 14px;
      display: block;
    }
  }
}

body {
  &:not(.style1) {
    .logout {
      margin-left: 3px;
      margin-right: 3px;
      display: block;
      margin-top: 0;

      &:hover {
        box-shadow: none;
      }

      span {
        width: 18px;
        height: 18px;
        background-image: url("../images/style1-exit.svg");
        display: block;
      }
    }
  }
}

.header-wrapper {
    padding-left: 50px;
    padding-right: 50px;
}

.header {
    padding-left: 0;
    padding-right: 0;
}

.filters {
    display: flex;
    align-items: center;
    padding-left: 60px;
    margin-bottom: 20px;
    font-size: 13px;
}

.filters-item {
    display: flex;
    align-items: center;
    margin-right: 17px;
}

.filters-round {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    background-color: gray;
    border: 1px solid transparent;
    border-radius:  50%;
}

.wrapper {
    padding-bottom: 48px;
}

.wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid var(--var-border-disabled);
    border-left: none;
    padding: 16px 16px 16px 20px;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
}

.wrapper.message {
    border-left: 1px solid var(--var-border-disabled);
}

.color-left-line {
    position: absolute;
    width: 10px;
    height: 100%;
    top: 50%;
    left: -4px;
    transform: translateY(-50%);
    border-left-color: transparent;
}

.default {
    background-color: var(--var-border-disabled);
}

.error {
    background-color: var(--var-border-red-vector);
}

.warning {
    background-color: var(--var-border-yellow-vector);
}

.success {
    background-color: var(--var-border-mint-vector);
}

.failed {
    background-color: var(--var-border-pink-vector);
}

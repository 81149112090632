@import '../../style/mixins';

.footer {
    display: flex;
    flex-direction: column;
}

.language-wrapper {
    border-bottom: 1px solid var(--var-border-disabled);
    button {
        transition: height .3s ease-in-out;
    }
}

.footer-open .floors{
    position: relative;
    width: 240px;
    min-height: 120px;
    max-height: 160px;
}

.footer-close .floors {
    position: relative;
    width: 60px;
    height: 60px;
}

.floors-item {
    white-space: nowrap;
}

.footer-open .floors-item {
    opacity: 1;
}

.footer-close .floors-item {
    opacity: 0;
}

.footer-closed .floors-close {
    animation: floors-close .3s ease-in-out forwards;
}

.footer-opened .floors-open {
    animation: floors-open .3s ease-in-out forwards;
}

.footer-closed .floors-close .floors-item {
    animation: opacity-down .3s ease-in-out forwards;
}

.footer-opened .floors-open .floors-item {
    animation: opacity-up .3s ease-in-out forwards;
}

.one-floor {
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px 24px;
    .font-caption1;
    color: var(--var-text-disabled);
    word-break: break-word;
    width: 240px;
    white-space: break-spaces;
}

.one-floor div:first-child {
    margin-right: 16px;
    margin-bottom: 4px;
}

.two-floor {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.item {
    display: flex;
    margin-bottom: 4px;
    justify-content: space-between;
    word-wrap: break-word;
    .font-body2_small-block;
}

.user-name {
    .font-body3_small-block;
    color: var(--var-text-secondary);
}

.full-name {
    max-width: 170px;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: auto;
}

.ico-exit {
    width: 20px;
    height: 20px;
    cursor: pointer;
    flex-shrink: 0;

    svg {
        fill: var(--var-icon-tirtiary);
        width: 100%;
        height: 100%;
    }

    svg:hover {
        fill: var(--var-icon-secondary);
    }
}

.only-icon {
    position: absolute;
    top: 16px;
    left: 50%;
    transition: opacity 0.3s ease-in-out;
    transform: translateX(-50%);
}

.footer-closed .only-icon {
    animation: opacity-up .3s ease-in-out forwards;
}

.footer-opened .only-icon {
    animation: opacity-down .3s ease-in-out forwards;
}

.tooltip {
    position: absolute;
}

.language-wrapper {
    transition: padding .3s ease-in-out;
}

:global(#main-container[data-open=true]) {
    .language-wrapper {
        padding: 8px;
    }
}

:global(#main-container[data-open=false]) {
    .language-wrapper {
        padding: 8px 10px;
    }
}

.language-wrapper :global(.language-select-language) {
    position: absolute;
    left: 90px;
    bottom: 6px;
    transition: left .3s ease-in-out, opacity .3s ease-in-out;
}

:global(#main-container[data-open=true]) .language-wrapper :global(.language-select-language) {
    opacity: 1;
}

:global(#main-container[data-open=false]) .language-wrapper :global(.language-select-language) {
    opacity: 0;
}

.language-wrapper :global(.language-select-flag) {
    position: absolute;
    bottom: 8px;
    transition: left .3s ease-in-out;
}

:global(#main-container[data-open=true]) .language-wrapper :global(.language-select-flag) {
    left: 70px;
}

:global(#main-container[data-open=false]) .language-wrapper :global(.language-select-flag) {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

:global(#main-container[data-open=false]) .language-wrapper :global(.dropdown-item-icon) {
    padding: 8px;
}

@keyframes opacity-down {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes opacity-up {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes floors-close {
    from {
        min-height: 120px;
        max-height: 160px;
        width: 240px;
    }

    to {
        min-height: 58px;
        max-height: 58px;
        width: 60px;
    }
}

@keyframes floors-open {
    from {
        min-height: 58px;
        max-height: 58px;
        width: 60px;
    }

    to {
        min-height: 120px;
        max-height: 160px;
        width: 240px;
    }
}

.e-container {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(2, 0, 25, 0.87);
}

.e-browser-error-wrapper {
    display: flex;
}

.e-browser-error-text {
    display: flex;
    flex-direction: column;
    width: 924px;
    justify-content: center;
}
.e-browser-error-title {
    line-height: 72px;
    font-size: 60px;
    font-weight: 900;
}
.e-browser-error-line {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
}

.e-browser-error-ico {
    width: 297px;
}

@media(max-width: 1440px){
    .e-browser-error-text {
        width: 677px;
    }
}
@media(max-width: 1280px){
    .e-browser-error-text {
        width: 677px;
    }
}

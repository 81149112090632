.title {
    margin-top: 17px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.body {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
}

.thead {
    display: flex;
    width: 100%;
    gap: 16px;
    border-bottom: 1px solid var(--var-light-other-divider);
}

.tcell {
    flex-shrink: 1;
    flex-grow: 1;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 0;
    width: 25%;
    font-weight: normal;
}

.thead>.tcell {
    font-weight: 600;
}

.tbody {
    display: flex;
    width: 100%;
    gap: 16px;
    border-bottom: 1px solid var(--var-light-other-divider);
}

.tbody:last-child {
    border: none;
}

@import "../../style/mixins";

.wrapper {
    width: 100%;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    .font-body2_small-block;
    margin-bottom: 16px;
}

.default {
    color: var(--var-text-primary);
    background-color: var(--var-fill-quaternary);
}

.warning {
    color: var(--var-text-yellow-vector);
    background-color: var(--var-fill-yellow-vector);
}

.error {
    color: var(--var-text-red-vector);
    background-color: var(--var-fill-red-vector);
}

.success {
    color: var(--var-text-mint-vector);
    background-color: var(--var-fill-mint-vector);
}

.blue {
    color: var(--var-text-blue);
    background-color: var(--var-fill-blue-vector);
}

@import '../../../../style/mixins';

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    width: 100%;

    &:hover {
        background-color: var(--var-fill-secondary);
    }

    &.lg {
        padding: 12px 24px;
        height: 48px;
        gap: 8px;
        .font-body1_small-block;
    }

    &.md {
        padding: 10px 24px;
        height: 40px;
        gap: 8px;
        .font-body2_small-block;
    }

    &.sm {
        padding: 7px 16px;
        height: 32px;
        gap: 4px;
        .font-body3_small-block;
    }
}

.disabled {
    color: var(--var-text-disabled);
    pointer-events: none;
}

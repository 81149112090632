@import '../../../React/style/mixins.less';

.wrapper-error {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.wrapper-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-error {
    .font-body2_small-block;
    color: var(--var-text-secondary);
    text-align: center;
    margin-top: 8px;
    width: 370px;
    &-bold {
        .font-small-title2_small-block;
        text-align: center;
        color: var(--var-text-primary);
    }
}

@import '../../style/mixins';

.tooltip-wrapper {
    display: flex;
    position: relative;
    align-items: center;

    &:hover>.react-tooltip {
        display: block;
    }
}

.react-tooltip {
    position: absolute;
    display: none;
    border-radius: 4px;
    padding: 4px 8px;
    width: max-content;
    text-align: left;
    background-color: var(--var-fill-primary-inverse);
    color: var(--var-text-primary-inverse);
    .font-body3_small-block;
    z-index: 100;
    box-shadow: 3px 6px 7px 1px var(--var-fill-secondary);
}

.top {
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 6px);
}

.right {
    left: calc(100% + 8px);
}

.left {
    right: calc(100% + 8px);
}

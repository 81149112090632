@import '../../../React/style/mixins';

.wrapper {
    svg {
        fill: var(--var-fill-disabled);
    }
}

.error-text {
    color: var(--var-text-red-vector);
}

.success-text {
    color: var(--var-text-mint-vector);
}

.text {
    .font-body3_small-block;
}

.diff-title {
    .font-small-title2_small-block;
    color: var(--var-text-primary);
    word-wrap: break-word;
}

.message-title {
    .font-body2_small-block;
    color: var(--var-text-primary);
    word-wrap: break-word;
}

.hint {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;
}

.message-text {
    .font-body3_small-block();
    color: var(--var-text-secondary);
}

.diff-text {
    .font-body3_small-block();
    color: var(--var-text-primary);
}

.time-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mb1 {
    margin-bottom: 16px;
}

.score {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--var-text-primary);
    .font-body2_small-block();
    width: fit-content;

    &-wrapper {
        margin-top: 8px;
        margin-bottom: 16px;
    }

    svg {
        fill: var(--var-icon-yellow-vector);
    }
}

.cert-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.cert-date {
    .font-caption2();
}

.cert-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.report-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.attack {
    color: var(--var-text-primary);
    &-rating {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    &-icon {
        width: 16px;
        height: 16px;

        &-wrapper {
            display: flex;
            align-items: center;
            gap: 4px;
            .font-body2_small-block;
        }

        &-success {
            svg {
                fill: var(--var-icon-mint-vector);
            }
        }

        &-warning {
            svg {
                fill: var(--var-icon-yellow-vector);
            }
        }

        &-error {
            svg {
                fill: var(--var-icon-red-vector);
            }
        }

        &-failed {
            svg {
                fill: var(--var-icon-pink-vector);
            }
        }
    }

    &-emotion-tooltip {
        display: flex;
        flex-direction: column;
        text-align: left;
        max-width: 220px;
        .font-caption2();
    }

    &-footer {
        display: flex;
        justify-content: space-between;
    }

    &-template {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--var-text-tertiary);
        .font-body3_small-block();
    }

    &-filling {
        display: flex;
        align-items: center;
        gap: 10px;

        &-icon {
            display: flex;
            width: 16px;
            height: 16px;
            fill: var(--var-icon-tirtiary);
        }

        &-text {
            color: var(--var-text-tertiary);
            cursor: default;
            .font-body3_small-block;
        }
    }

    &-emotions {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

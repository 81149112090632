@import '../../../React/style/mixins.less';

.container {
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   gap: 4px;
   margin: 0;
   width: 100%;
   color: var(--var-text-secondary);
}

.text-field-hint {
   box-sizing: border-box;
   border: 2px solid;
   position: relative;

   &.lg {
      padding: 10px 12px;
      height: 60px;
      border-radius: 8px;
   }

   &.md {
      padding: 6px 12px 7px;
      height: 48px;
      border-radius: 6px;
   }
}

.text-field-hint {
   border-color: var(--var-border-secondary);
   &:hover {
      border-color: var(--var-border-tirtiary);
   }
   &.focus {
      border-color: var(--var-border-accent);
   }
}

.input-wrapper {
    width: 100%;
    display: inline-block;
    position: relative;
}

.input {
   margin: 0;
   padding: 0;
   border: none;
   background: transparent;
   width: 100%;
   outline: none;
   box-shadow: none;
   position: absolute;
   height: initial !important;
   top: -4px;
}

.input {
   color: var(--var-text-primary);

   &.lg {
      .font-body1_small-block;
   }

   &.md {
      .font-body2_small-block;
   }
}

.placeholder {
   position: absolute;
}

.placeholder-start {
   top: 50%;
   transform: translateY(-50%);

   &.lg {
      .font-body1_small-block;
   }

   &.md {
      .font-body2_small-block;
   }
}

.placeholder-end {

   &.lg {
      .font-caption2;
      top: 8px;
   }
   &.md {
      .font-caption3;
      top: 4px;
   }
}

.placeholder-animate,
.placeholder-animate-reverse {
   animation-duration: .2s;
   animation-timing-function: ease-in-out;
   animation-fill-mode: forwards;
}

.placeholder-animate.lg {
   animation-name: placeholder-lg;
}

.placeholder-animate.md {
   animation-name: placeholder-md;
}

.placeholder-animate-reverse.lg {
   animation-name: placeholder-reverse-lg;
}
.placeholder-animate-reverse.md {
   animation-name: placeholder-reverse-md;
}

@keyframes placeholder-lg {
   from {
      .font-body1_small-block;
       top: 50%;
       transform: translateY(-50%);
   }
   to {
      .font-caption2;
       top: 8px;
   }
}

@keyframes placeholder-md {
   from {
      .font-body2_small-block;
       top: 50%;
       transform: translateY(-50%);
   }
   to {
      .font-caption3;
       top: 4px;
   }
}

@keyframes placeholder-reverse-lg {
   from {
       .font-caption2;
       top: 16px;
   }
   to {
      .font-body1_small-block;
       top: 50%;
       transform: translateY(-50%);
   }
}

@keyframes placeholder-reverse-md {
   from {
       .font-caption3;
       top: 12px;
   }
   to {
      .font-body2_small-block;
       top: 50%;
       transform: translateY(-50%);
   }
}

.text-under-field {
   .font-caption2;
}

.error {
   .text-field-hint {
      border-color: var(--var-border-error);

      &:hover,
      &.focus {
         border-color: var(--var-border-error-hover);
      }
   }

   .text-under-field {
      color: var(--var-text-error-description);
   }
}

.disabled {
   pointer-events: none;
   color: var(--var-text-disabled);

   .text-field-hint {
      background-color: var(--var-fill-disabled);
      border-color: var(--var-border-disabled);
   }

   .input {
      color: var(--var-text-disabled);
   }
}

.wrapper {
    width: 100%;
    display: grid;
    gap: 24px;
}

.fields,
.login {
    display: grid;
    gap: 16px;
}

.outlook {

    &_item {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 1.5rem;
    }

    &_logo {
        display: flex;
        width: 57px;
        height: 64px;
    }

    &_button {
        width: -moz-fit-content;
        width: fit-content;
        padding-left: 32px;
        padding-right: 32px;
    }

    &_text {
        display: flex;
        flex-direction: column;
    }

    &_subtext {
        margin-bottom: 16px;
        line-height: 19px;
    }

    &_manifest {
        display: flex;
        gap: 16px;
    }

    &_mr15 {
        margin: 0 15px;
    }

    &_path {
        .col();
        .size(6);
    }

    &_radio {
        align-items: center;
    }

    &_radio-wrapper {
        margin-bottom: .8rem;
    }

    &_radiobutton {
        margin-right: .8rem;
    }

    &_checkbox {
        margin-right: .8rem;
    }

    &_sub-header {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        margin-bottom: 17px;
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;

        &__right {
            .col();
            .size(9);
            margin-left: auto;
        }
    }

    &_sub-text {
        font-size: 1rem;
        font-weight: normal;
        margin-top: 0.3rem;
    }

    &_label {
        align-self: baseline;
        text-align: left;
    }

    &_uploader {
        display: none!important;
    }

    &_file-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_dragover {
        display: flex;
        flex-direction: column;
        padding: 8px 16px;
        height: 60px;
        width: 100%;
        border: 1px dashed @main-color;
        border-radius: 4px;

        &__error {
            background-color: @error-background;
            border-color: @error-color;
        }

        &__valid {
            background-color: @success-background;
        }
    }

    &_preview-wrapper {
        .col();
        .size(9);
        margin-left: auto;
        padding: 20px;
        padding-top: 32px;
        overflow: auto;
        border: 1px solid @main-color;
        border-radius: 4px;
    }

    &_feedback-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    &_link-generate {
        margin-right: .5rem;
    }

    &_copy-wrapper {
        cursor: pointer;
    }
}

.feedback-link {
    margin-top: 1rem;
    flex-grow: 2;
    color: #ccc;
}

.dragover {

    &_text {
        margin: 0 auto;
    }

    &_label {
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: @title-color;
    }
}

.outlook-form {
    width: 100%;
    box-sizing: border-box;

    &_header {
        position: sticky;
        top: 0px;
        background-color: white;
        display: flex;
        align-items: baseline;
        border-bottom: 1px solid @main-color;
        padding-top: 20px;
        margin: 0 15px;
        margin-bottom: 2rem;
        z-index: 10;

        &-item {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            box-sizing: border-box;
            line-height: 20px;
            padding-bottom: 1em;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;

            &:hover, &__active {
                color: @link-color;
                box-shadow: 0 3px 0 -1px @link-color;
            }
        }

        &-button {
            position: relative;
            margin-left: auto;
            font-size: 12px;
            padding: 4px 8px 4px 29px;
            background-color: @link-color;
            border-radius: 4px;
            color: white;
            line-height: 16px;
            cursor: pointer;

            &::before {
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 16px;
                content: '';
                width: 1px;
                height: 9px;
                background-color: white;
            }

            &::after {
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 12px;
                content: '';
                width: 9px;
                height: 1px;
                background-color: white;
            }
        }
    }

    &_input {
        height: 32px !important;
    }

    &_main {
        .row();
    }
}


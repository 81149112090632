@font-weight-caption-and-body: 400;
@font-weight-accidental: 1000;
@font-weight-title: 600;
@XTRA-general: "wdth" 120, "XTRA" 470;
@XTRA-accidental: "wdth" 150, "XTRA" 500;

@font-family: 'Roboto flex variable', 'Roboto flex', sans-serif;

.accidental {
   font-family: @font-family;
   font-weight: @font-weight-accidental;
   font-variation-settings: @XTRA-accidental;
}

.title {
   font-family: @font-family;
   font-weight: @font-weight-title;
   font-variation-settings: @XTRA-general;
}

.body-caption {
   font-family: @font-family;
   font-weight: @font-weight-caption-and-body;
   font-variation-settings: @XTRA-general;
}

.font-size-line-height(@font-size, @line-height) {
   font-size: @font-size;
   line-height: @line-height;
}

.font-accidental(@font-size, @line-height) {
   .accidental;
   .font-size-line-height(@font-size, @line-height);
}

.font-title(@font-size, @line-height) {
   .title;
   .font-size-line-height(@font-size, @line-height);
}

.font-body(@font-size, @line-height) {
   .body-caption;
   .font-size-line-height(@font-size, @line-height);
 }

.font-caption(@font-size, @line-height) {
   .body-caption;
   .font-size-line-height(@font-size, @line-height);
}




.font-accidental-title1() {
   .font-accidental(80px, 88px);
}

.font-accidental-title2() {
   .font-accidental(64px, 72px);
}

.font-title1() {
   .font-title(60px, 72px);
}

.font-title2() {
   .font-title(48px, 56px);
}

.font-title3() {
   .font-title(40px, 48px);
}

.font-title4() {
   .font-title(32px, 40px);
}

.font-small-title1() {
   .font-title(24px, 32px);
}

.font-small-title1_small-block() {
   .font-title(24px, 28px);
}

.font-small-title2() {
   .font-title(20px, 28px);
}

.font-small-title2_small-block() {
   .font-title(20px, 24px);
}

.font-small-title3() {
   .font-title(16px, 24px);
}

.font-small-title3_small-block() {
   .font-title(16px, 20px);
}

.font-body1() {
   .font-body(20px, 28px);
}

.font-body1_small-block() {
   .font-body(20px, 24px);
}

.font-body2() {
   .font-body(16px, 24px);
}

.font-body2_small-block() {
   .font-body(16px, 20px);
}

.font-body3() {
   .font-body(14px, 20px);
}

.font-body3_small-block() {
   .font-body(14px, 18px);
}

.font-caption1() {
   .font-caption(14px, 20px);
}

.font-caption2() {
   .font-caption(12px, 16px);
}

.font-caption3() {
   .font-caption(11px, 15px);
}

.font-overline-capital() {
   .font-caption(12px, 14px);
}

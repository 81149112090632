.wrapper {
	padding-top: 24px;
	padding-left: 44px;
	padding-right: 44px;
}

.title {
	font-size: 22px;
	color: black;
	margin-bottom: 10px;
	letter-spacing: .6px;
	cursor: pointer;
}

.active {
	 font-weight: 600;
}

.item-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 24px 0 0 47px;
}

.item {
	position: relative;
	display: flex;
	flex-direction: column;
}

.item-title {
	margin-bottom: 18px;
}

.item-mr {
	margin-right: 10px;
}

.square {
	display: flex;
	min-width: 155px;
	height: 110px;
	align-items: center;
	justify-content: center;
}

.square-danger {
	background-color: #DA5855;
}

.square-medium {
	background-color: #A8D8A8;
	opacity: .7;
}

.square-warning {
	background-color: #F5A829;
}

.square-succsess {
	background-color:#5EB75D;
}

.square-gray {
	background-color: gray;
}

.square-danger:hover {
	background-color: #DA5855;
}

.square-warning:hover {
	background-color: #F5A829;
}

.square-succsess:hover {
	background-color: #5EB75D;
}

.text-white {
	font-size: 52px;
	color: white;
	cursor: default;
	background-color: transparent;
}

.percent {
	font-size: 23px;
}

.separate {
	position: absolute;
	top: 25px;
	right: -52px;
	width: 1px;
	height: 137px;
	background-color: #ddd;
}

.separate-arrow {
	position: absolute;
	right: -56px;
	top: 60%;
	width: 20px;
	height: 20px;
	border-top: 3px solid #d95350;
	border-right: 3px solid #d95350;
	transform: rotate(45deg);
}

.separate-danger {
	border-color: #d95350;
}

.separate-warning {
	border-color: #f5a623;
}

.separate-succsess {
	border-color: #5CB75C;
}

.bottom-path {
	display: flex;
	padding-left: 46px;
	flex-basis: 100%;
}

.text {
	flex-grow: 4.8;
	width: 232px;
	padding-right: 10px;
	flex-direction: column;
}

.inner-text {
	display: inline-block;
	margin-bottom: 5px;
}

.link {
	line-height: 2rem;
}

.graph {
	flex-grow: 13;
	height: 100px;
	width: 600px;
}

.item-wrapper:hover .grey {
    filter: grayscale(1);
}

.item-wrapper .grey:hover {
    filter: grayscale(0);
}

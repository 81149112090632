@import '../../../React/style/mixins.less';

.login-page {
    display: flex;
    justify-content: center;
}

.wrapper-left-part {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.wrapper-right-part {
    background-color: var(--var-bg-secondary);
    width: 100%;
}

.left-part {
    position: relative;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    width: 50vw;
    max-width: 960px;
    height: 100vh;

:global(.footer-login) {
        --popup: fit-content;
    }
}

.right-part {
    background-color: var(--var-bg-secondary);
    width: 50vw;
    max-width: 960px;
    height: 100vh;
}

.wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 372px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    .font-body2_small-block;
    margin-bottom: 32px;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 8px auto;
}

.logo-img {
    width: 248px;
    min-height: 40px;
    max-height: 80px;
}

.description {
    color: var(--var-text-primary);
    text-align: center;
    padding: 0 50px;
}

.img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;

    &.ru {
        background-image: url('../../../../images/login-ru.png');
    }

    &.en {
        background-image: url('../../../../images/login-en.png');
    }

    &.it {
        background-image: url('../../../../images/login-it.png');
    }
}

@media screen and (max-width: 1439px){
    .left-part {
        padding: 0 20px;
    }

    .login-form {
        width: 324px;
    }

    .header {
        .font-body3_small-block;
        margin-bottom: 24px;
    }
}

@font-face {
    font-family: 'Roboto flex variable';
    src: local('Roboto flex variable'), local('RobotoFlexVariable'), url('../fonts/roboto-flex-variable/RobotoFlex-variable.woff2') format('woff2'), url('../fonts/roboto-flex-variable/RobotoFlex-variable.woff') format('woff');
    font-style: normal;
    font-stretch: 1% 500%;
}

@font-face {
    font-family: 'Roboto flex';
    src: local('Roboto flex'), local('RobotoFlex'), url('../fonts/roboto-flex/RobotoFlex-Regular.woff2') format('woff2'), url('../fonts/roboto-flex/RobotoFlex-Regular.woff') format('woff');
    font-style: normal;
    font-stretch: 1% 500%;
}

@font-face {
    font-family: 'Open Sans';
    src: local("Open Sans"), local("OpenSans"), url("../fonts/opensans.woff2") format("fonts/woff2"), url("../fonts/opensans.woff") format("woff"), url("../fonts/opensans.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../fonts/opensansitalic.woff2") format("/fonts/woff2"), url("../fonts/opensansitalic.woff") format("woff"), url("../fonts/opensansitalic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("../fonts/opensanssemibold.woff2") format("woff2"), url("../fonts/opensanssemibold.woff") format("woff"), url("../fonts/opensanssemibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/opensansbold.woff2") format("woff2"), url("../fonts/opensansbold.woff") format("woff"), url("../fonts/opensansbold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}


.wrapper {
    position: relative;
}

.popup {
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid var(--var-border-disabled);
    position: absolute;
    bottom: calc(100% + 4px);
    background: var(--var-fill-primary);
    box-shadow: 0 4px 24px 0 var(--var-fill-secondary);
    width: 100%;
}

.xs {
    border-radius: 4px;
}

.lg {
    border-radius: 6px;
}

.button-content {
    width: 80px;
    display: flex;
    gap: 4px;
}

.flag {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 16px;
    height: 16px;
}

.svg {
    width: 100%;
    height: 100%;
}

.svg.disabled {
    opacity: 0.23;
}

.md {
    width: 20px;
    height: 20px;
}

.settings {

    &_new-pass {
        margin-bottom: 1.86rem;
    }

    &-wrapper {
        .react-wrapper();
        padding-left: 0;
        padding-right: 0;
        padding-top: 24px;
    }

    &_row {
        .row();
        margin-bottom: 3.1rem;
        justify-content: space-between;
    }

    &_path {
        .col();
        .size(6);
        font-size: 14px;
    }

    &_full {
        .col();
    }

    &_title {
        font-weight: 600;
        color: @title-color;
        margin-bottom: 1.24rem;
    }

    &_item {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        margin-bottom: 1.86rem;

        &__center {
            align-items: center;
        }

        &__start {
            align-items: flex-start;
        }

        &__last {
            margin-bottom: 0;
        }

        &__license-change {
            display: flex;
            flex-direction: column;
            white-space: pre;
        }
    }

    &_path-right {
        .col();
        width: 100%;
    }

    &_path__right {
        .col();
        padding-left: 50px;
    }

    &_path-left {
        .size(5);
        flex-shrink: 0;
        text-align: left;
    }

    &_progress-wrapper {
        display: flex;
        height: 20px;
        width: 300px;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    }

    &_licence-count {
        height: 20px;
        background-color: rgba(155, 155, 155, .45);
    }

    &_real-count {
        height: 20px;
        width: 50%;
        background-color: #5cb85c;
    }

    &_not-licence {
        height: 20px;
        opacity: .55;
        background-color: #DA5855;
    }
    &_multiple {
        width: 58%;
        margin-left: 0;
    }
    &-auth_label {
        margin-right: 45px;
        margin-left: 0;
    }
}

.notification-alert-wrapper {
    margin: 1.24rem 0;
}

.row-wrapper {
    .row();
}

@import '../../../../style/mixins';

:global(.footer-login) {
    display: flex;
    justify-content: space-between;
    height: 72px;
    padding: 16px 0 24px;
    --popup: 100%;
}

.lang {
    position: relative;
}

.right-part {
    display: flex;
    gap: 24px;
}

.support span {
    .font-body3_small-block;
    color: var(--var-text-primary);
    margin-right: 8px;
}

@import '../../style/mixins.less';

.body {
    padding: 0 48px 48px;
    display: flex;
    flex-direction: column;

    &-element-wrapper {
        .font-body2_small-block;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        color: var(--var-text-primary);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-label {
        width: 170px;
        flex-grow: 0;
        margin-right: 48px;
        color: var(--var-text-tertiary);
        &-wrapper {
            display: flex;
            align-items: center;
            width: 218px;
        }
    }

    &-icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;

        svg {
            fill: var(--var-icon-tirtiary);
        }
    }

    &-value {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
    }

    &-no-value {
        color: var(--var-text-disabled);
    }
}

@import '../../../React/style/mixins';

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
}

.header-plug {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 24px 48px 32px;

    &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-name {
        margin-top: 8px;
        width: 440px;
        height: 40px;
        border-radius: 8px;
        background-color: var(--var-fill-secondary);
    }

    &-status {
        width: 88px;
        height: 26px;
        border-radius: 8px;
        background-color: var(--var-fill-quaternary);
    }

    &-rating {
        width: 148px;
        height: 62px;
        border-radius: 8px;
        background-color: var(--var-fill-quaternary);
    }
}

.wrapper-plug {
    overflow: auto;
}

.shadow {
    box-shadow: inset 0 4px 16px var(--var-fill-secondary);
}

.body-plug {
    padding: 0 48px 48px;
    display: flex;
    flex-direction: column;

    &-element-wrapper {
        .font-body2_small-block;
        display: flex;
        margin-bottom: 20px;
        color: var(--var-text-primary);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-label {
        width: 170px;
        flex-grow: 0;
        margin-right: 48px;
        color: var(--var-text-tertiary);
        &-wrapper {
            display: flex;
            align-items: center;
            width: 218px;
        }
    }

    &-icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;

        svg {
            fill: var(--var-icon-tirtiary);
        }
    }

    &-value {
        width: 200px;
        height: 20px;
        border-radius: 8px;
        background-color: var(--var-fill-quaternary);
    }
}

.select-wrapper {
    position: sticky;
    top: 0;
    padding: 0 48px;
    background-color: white;
    z-index: 1;
}

.select-name {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.count {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: var(--var-fill-quaternary);
}

.select-shadow {
    box-shadow: 0 4px 16px var(--var-fill-secondary);
}

.shadow {
    box-shadow: inset 0 4px 16px var(--var-fill-secondary);
}

.main-wrapper {
    padding: 32px 48px 48px;
    height: calc(100vh - 615px);
    min-height: 586px;
    display: flex;
    flex-direction: column;
}

.title-wrapper {
    width: 440px;
    height: 40px;
    border-radius: 8px;
    background-color: var(--var-fill-secondary);
}

.cards-wrapper {
    width: 100%;
    display: flex;
    padding-top: 24px;
    flex-grow: 1;
}

.edu {
    width: 25%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    &:last-child {
        margin: 0;
    }
}

.card {
    min-height: 374px;
    border-radius: 16px;
    background-color: var(--var-fill-quaternary);
    flex-grow: 1;
}

.animate {
    position: relative;
    overflow: hidden;
}

.animate::before {
    content: "";
    position: absolute;
    width: 140%;
    height: 120%;
    background: linear-gradient(-70deg, transparent 0%, transparent 30%, var(--var-text-primary-inverse) 50%, transparent 60%, transparent 100%);
    opacity: 0.4;
    animation: animate 2.5s linear infinite;
}

@keyframes animate {
    from {
        left: -100%;
    }
    to {
        left: 100%;
    }
}

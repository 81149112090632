@import '../../../React/style/mixins';

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--var-border-disabled);
}

.tab {
    all: initial;
    position: relative;
    .font-small-title2_small-block;
    color: var(--var-text-secondary);
    padding: 16px 24px 24px;

    &:hover {
        color: var(--var-text-primary);
        cursor: pointer;
        box-shadow: none;
    }
}

.tab:disabled {
    color: var(--var-text-secondary);
    pointer-events: none;
}

.active {
    color: var(--var-text-primary);
    &:hover {
        cursor: default;
    }
}

.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: var(--var-border-accent);
}

@import '../../../React/style/mixins.less';

.button {
    all: initial;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    justify-content: center;
    background-color: transparent;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;

   &:hover {
      cursor: pointer;
   }

   &.lg {
      height: 60px;
      border-radius: 8px;
      padding: 18px 24px;
      gap: 8px;
      .font-body1_small-block;
   }

   &.md {
      height: 48px;
      border-radius: 8px;
      padding: 14px 24px;
      gap: 4px;
      .font-body2_small-block;
   }

   &.sm {
      height: 40px;
      border-radius: 6px;
      padding: 10px 20px;
      gap: 4px;
      .font-body2_small-block;
   }

   &.xsm {
      height: 32px;
      border-radius: 4px;
      padding: 7px 16px;
      gap: 4px;
      .font-body3_small-block;
   }
}

.button.primary {
    color: var(--var-text-primary-inverse);
    background-color: var(--var-fill-accent);

    &:hover,
    &:focus-visible {
        background-color: var(--var-fill-accent-hover);
    }

    &:active {
        background-color: var(--var-fill-accent);
    }
}

.button.secondary {
    color: var(--var-text-primary);
    background-color: var(--var-fill-secondary);

    &:hover,
    &:focus-visible {
        background-color: var(--var-fill-tertiary-light);
    }

    &:active {
        background-color: var(--var-fill-secondary);
    }
}

.button.ghost {
    color: var(--var-text-primary);
    background-color: transparent;

    &:hover,
    &:focus-visible {
        background-color: var(--var-fill-tertiary-light);
    }

    &:active {
        background-color: transparent;
    }
}

.loading {
    pointer-events: none;
    &:hover,
    &:focus-visible {
        background-color: transparent;
    }

    &:active {
        background-color: transparent;
    }
}

.loading::before {
    content: "";
    position: absolute;
    top: 0;
    width: 80%;
    height: 100%;
    background: linear-gradient(-45deg, transparent 0%, transparent 30%, var(--var-text-primary-inverse) 50%, transparent 70%, transparent 100%);
    opacity: 0.1;
    animation: loading 2.5s linear infinite;
}

.button.disabled {
    pointer-events: none;
    color: var(--var-text-disabled);
}

.button.disabled.primary,
.button.disabled.secondary {
    background-color: var(--var-fill-disabled);
}

@keyframes loading {
    from {
        left: -70%;
    }
    to {
        left: 70%;
    }
}

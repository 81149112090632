.wrapper {
    width: 100%;
    padding-bottom: 48px;
}

.inner-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    padding-top: 12px;
    font-size: 14px;
}

.menu-item {
    width: 25%;
    padding: 0 45px;
    margin-bottom: 40px;
    font-size: inherit;
}

.menu-item:nth-child(n+5) {
    margin-bottom: 0;
}

.item-header {
    display: inline-block;
    white-space: nowrap;
    color: black;
    font-size: 22px;
}

.item-title {
    position: relative;
    display: inline-block;
    padding-top: 17px;
    font-size: inherit;
    line-height: 19px;
}

.item-default {
    display: block;
    width: 100%;
    height: 26px;
}

.item-link {
    position: relative;
    display: inline-block;
    font-size: inherit;
    line-height: 1.36em;
    margin-bottom: .3rem;
}

.first-section-title {
    margin-top: 39px;
}

.second-section-title {
    margin-top: 22px;
}

.item-padding {
    margin-top: 26px;
}

.ico {
    position: absolute;
    left: -25px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
}

.ico-history {
    background-image: url('../../../images/history.svg');
}

.ico-attack {
    width: 25px;
    height: 25px;
    left: -18px;
    background-image: url(../../../images/attack.svg);
}

.ico-learning {
    background-image: url('../../../images/book.svg');
}

.ico-report {
    left: -21px;
    background-image: url('../../../images/report.svg');
}

.ico-scheduler {
    background-image: url('../../../images/scheduler.svg');
}

.white-none {
    white-space: normal;
}

.search-wrapper {
    display: flex;
    align-items: baseline;
}

.search-input {
    width: 600px!important;
}

.menu-search {
    width: 100%;
    padding: 0 45px 32px;
}

.close-search {
    margin-left: 24px;
    font-size: 22px;
}

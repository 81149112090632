@import "../../style/mixins";

.select-wrapper {
    position: sticky;
    top: 0;
    padding: 0 48px;
    background-color: white;
    z-index: 100;
}

.filter {
    position: relative;

    &-title {
        .font-small-title3_small-block();
        color: var(--var-text-primary);
        padding: 0 12px 4px 12px;
    }

    &-row {
        padding: 8px 0;
        width: 100%;
    }

    &-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
    }

    &-svg {
        width: 20px;
        height: 20px;

        svg {
            width: 100%;
            height: 100%;
            fill: var(--var-icon-primary);
        }
    }

    &-svg-rotate {
        transform: rotate(180deg);
    }

    &-svg-disabled {

        svg {
            fill: var(--var-icon-disabled);
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--var-border-disabled);
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .08);
        background-color: white;
        top: calc(100% + 3px);
        z-index: 1;

        &-open {
            display: block;
        }
    }
}

.main-wrapper {
    padding: 32px 48px 48px;
    min-height: ~"calc(100vh - 267px)";
}

.select-name {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.count {
    padding: 3px 6px;
    border-radius: 4px;
    background-color: var(--var-fill-quaternary);
    .font-body3_small-block;
    color: var(--var-text-disabled);
}

.active-count {
    color: var(--var-text-primary);
}

.cards-wrapper {
    width: 100%;
    display: flex;
    padding-top: 24px;
}

.edu {
    width: 25%;
    margin-right: 16px;
    &:last-child {
        margin: 0;
    }
}

.attack {
    width: 33.333%;
    margin-right: 16px;
    &:last-child {
        margin: 0;
    }
}

.report {
    width: 50%;
    margin-right: 16px;
    &:last-child {
        margin: 0;
    }
}

.shadow {
    box-shadow: 0 4px 16px var(--var-fill-secondary);
}

.title {
    .font-title4();
    word-wrap: break-word;
    color: var(--var-text-primary);
}

.title-wrapper {
    display: flex;
    justify-content: space-between;
}

.radio-wrapper {
    display: flex;
}

.sort {
    &-label {
        color: var(--var-text-tertiary);
    }

    &-text {
        display: flex;
        gap: 4px;
        .font-body2_small-block;
    }
}

:root {
    --var-fill-accent: #5F14FF;
    --var-fill-primary: rgb(255, 255, 255);
    --var-fill-primary-inverse: rgba(2, 0, 25, 1);
    --var-fill-secondary: rgba(2, 0, 25, .08);
    --var-fill-accent-hover: #3600AF;
    --var-fill-accent-vector: #F2EEFF;
    --var-fill-accent-vector-hover: #EBE6FF;
    --var-fill-orange-vector: #FFF3E0;
    --var-fill-orange-vector-hover: #FFF3E0;
    --var-fill-red-vector: #FEEBEE;
    --var-fill-red-vector-hover: #FECDD2;
    --var-fill-pink-vector: #FCE8FF;
    --var-fill-pink-vector-hover: #F6CEFD;
    --var-fill-blue-vector: #E1F5FE;
    --var-fill-blue-vector-hover: #B3E5FC;
    --var-fill-green-vector:#F1F8E9;
    --var-fill-green-vector-hover: #DCEDC8;
    --var-fill-mint-vector: #E8F5E9;
    --var-fill-mint-vector-hover: #C8E6C9;
    --var-fill-yellow-vector: #FFFDE7;
    --var-fill-yellow-vector-hover: #FFF9C4;
    --var-fill-disabled: rgba(2, 0, 25, .12);
    --var-fill-perfect: #0091EA;
    --var-fill-great: #00C853;
    --var-fill-good: #64DD17;
    --var-fill-tertiary-light: rgba(2, 0, 25, .12);
    --var-fill-tertiary-dark: rgba(2, 0, 25, .23);
    --var-fill-quaternary: rgba(2, 0, 25, .04);
    --var-fill-acceptable: #FFD600;
    --var-fill-dangerous: #FF6D00;
    --var-fill-critical: #D50000;
    --var-fill-error: #FEEBEE;
    --var-text-primary: rgba(2, 0, 25, .87);
    --var-text-secondary: rgba(2, 0, 25, .6);
    --var-text-tertiary: rgba(2, 0, 25, .38);
    --var-text-disabled: rgba(2, 0, 25, .38);
    --var-text-accent: #5F14FF;
    --var-text-accent-vector: #3600AF;
    --var-text-primary-inverse: #FFF;
    --var-text-error-description: rgb(244, 67, 54);
    --var-text-good: #558B2F;
    --var-text-acceptable: #F9A825;
    --var-text-hight: #C62828;
    --var-text-error: #B71C1C;
    --var-text-red-vector: #B71C1C;
    --var-text-yellow-vector: rgb(86, 42, 4);
    --var-text-mint-vector: #1B5E20;
    --var-text-blue: #01579B;
    --var-bg-secondary: rgb(235, 235, 237);
    --var-text-error: #B71C1C;
    --var-bg-primary: #FFF;
    --var-icon-primary: rgba(2, 0, 25, .87);
    --var-icon-primary-inverse: #FFF;
    --var-icon-accent: rgb(95, 20, 255);
    --var-icon-tirtiary: rgba(2, 0, 25, .38);
    --var-icon-secondary: rgba(2, 0, 25, .6);
    --var-icon-disabled: rgba(2, 0, 25, .38);
    --var-icon-yellow-vector: rgb(251, 192, 45);
    --var-icon-red-vector: rgb(244, 67, 54);
    --var-icon-mint-vector: rgb(76, 175, 80);
    --var-icon-pink-vector: rgb(218, 61, 244);
    --var-border-disabled: rgba(2, 0, 25, 0.12);
    --var-border-primary: rgba(2, 0, 25, .87);
    --var-border-secondary: rgba(2, 0, 25, .23);
    --var-border-tirtiary: rgba(2, 0, 25, .3);
    --var-border-accent: rgb(95, 20, 255);
    --var-border-accent-hover: rgba(54, 0, 175, 1);
    --var-border-accent-light: rgb(202, 187, 255);
    --var-border-yellow-vector: var(--var-fill-yellow-vector-hover);
    --var-border-red-vector: var(--var-fill-red-vector-hover);
    --var-border-mint-vector: var(--var-fill-mint-vector-hover);
    --var-border-pink-vector: var(--var-fill-pink-vector-hover);
    --var-border-error: rgb(244, 67, 54);
    --var-border-error-hover: rgb(211, 47, 47);
    --var-border-critical: #D50000;
    --var-border-dangerous: #FF6D00;
    --var-border-acceptable: #FFD600;
    --var-border-good: #64DD17;
    --var-border-perfect: #0091EA;
    --dark-blue-400: rgba(2, 0, 25, .23);
    --var-fill-accent-vector: #F2EEFF;
    --var-light-other-divider: rgba(0, 0, 0, .12);
}

[data-theme='dark'] {
    --var-fill-accent: #6E3BFF;
    --var-fill-primary: rgba(255, 255, 255, .12);
    --var-fill-primary-inverse: rgba(2, 0, 25, 1);
    --var-fill-secondary: rgba(255, 255, 255, .08);
    --var-fill-accent-hover: #3600AF;
    --var-fill-accent-vector: #F2EEFF;
    --var-fill-accent-vector-hover: #EBE6FF;
    --var-fill-orange-vector: #FFF3E0;
    --var-fill-orange-vector-hover: #FFF3E0;
    --var-fill-red-vector: #FEEBEE;
    --var-fill-red-vector-hover: #FECDD2;
    --var-fill-pink-vector: #FCE8FF;
    --var-fill-pink-vector-hover: #F6CEFD;
    --var-fill-blue-vector: #E1F5FE;
    --var-fill-blue-vector-hover: #B3E5FC;
    --var-fill-green-vector:#F1F8E9;
    --var-fill-green-vector-hover: #DCEDC8;
    --var-fill-mint-vector: #E8F5E9;
    --var-fill-mint-vector-hover: #C8E6C9;
    --var-fill-yellow-vector: #FFFDE7;
    --var-fill-yellow-vector-hover: #FFF9C4;
    --var-fill-disabled: rgba(255, 255, 255, .12);
    --var-fill-perfect: rgb(64, 196, 255);
    --var-fill-great: rgb(105, 240, 174);
    --var-fill-good: rgb(178, 255, 89);
    --var-fill-tertiary-light: rgba(255, 255, 255, .3);
    --var-fill-tertiary-dark: rgba(255, 255, 255, .4);
    --var-fill-quaternary: rgba(2, 0, 25, .04);
    --var-fill-acceptable: rgb(255, 214, 0);
    --var-fill-dangerous: rgb(245, 124, 0);
    --var-fill-critical: rgb(244, 67, 54);
    --var-fill-error: #FEEBEE;
    --var-text-primary: #FFF;
    --var-text-secondary: rgba(255, 255, 255, .7);
    --var-text-tertiary: rgba(2, 0, 25, .38);
    --var-text-disabled: rgba(255, 255, 255, .5);
    --var-text-accent: #6E3BFF;
    --var-text-accent-vector: #3600AF;
    --var-text-primary-inverse: #FFF;
    --var-text-error-description: rgb(244, 67, 54);
    --var-text-good: #B2FF59;
    --var-text-acceptable: #FFD600;
    --var-text-hight: #F44336;
    --var-text-red-vector: #B71C1C;
    --var-text-mint-vector: #1B5E20;
    --var-bg-secondary: black;
    --var-bg-primary: rgba(255, 255, 255, .12);
    --var-icon-primary: #FFF;
    --var-icon-primary-inverse: #FFF;
    --var-icon-accent: rgb(95, 20, 255);
    --var-icon-tirtiary: rgba(2, 0, 25, 38);
    --var-icon-secondary: rgba(2, 0, 25, .6);
    --var-icon-disabled: rgba(255, 255, 255, .3);
    --var-icon-yellow-vector: rgb(251, 192, 45);
    --var-border-disabled: rgba(255, 255, 255, .12);
    --var-border-primary: #FFF;
    --var-border-secondary: rgba(255, 255, 255, .23);
    --var-border-tirtiary: rgba(2, 0, 25, .3);
    --var-border-accent: rgb(144, 115, 255);
    --var-border-accent-hover: rgba(54, 0, 175, 1);
    --var-border-accent-light: rgba(144, 115, 255, .23);
    --var-border-error: rgb(244, 67, 54);
    --var-border-error-hover: rgb(211, 47, 47);
    --var-border-critical: #D50000;
    --var-border-dangerous: #FF6D00;
    --var-border-acceptable: #FFD600;
    --var-border-good: #64DD17;
    --var-border-perfect: #0091EA;
    --dark-blue-400: rgba(2, 0, 25, .23);
}

@import '../../../React/style/mixins';

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 24px 48px 32px;

    &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-name {
        margin-top: 8px;
        color: var(--var-text-primary);
        .font-title4();
    }

    &-status {
        color: var(--var-text-accent-vector);
    }

    &-rating {
        display: flex;
        gap: 8px;
        margin-top: 16px;
        color: var(--var-text-primary);
        .font-small-title1_small-block();
    }

    &-text-rating {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--var-text-secondary);
        .font-body3_small-block();

        svg {
            fill: var(--var-icon-secondary);
        }
    }

    &-rating-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.header-rating-ico-up,
.header-rating-ico-down {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 50%;
}

.header-rating-ico-up {
    background-color: var(--var-fill-mint-vector);
    transform: rotateX(180deg);

    svg {
        fill: var(--var-text-mint-vector);
    }
}

.header-rating-ico-down {
    background-color: var(--var-fill-red-vector);

    svg {
        fill: var(--var-text-red-vector);
    }
}

.wrapper {
    overflow: auto;
}

.shadow {
    box-shadow: inset 0 4px 16px var(--var-fill-secondary);
}

.menu-item {
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 8px;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
}

.menu-item:hover {
    cursor: pointer;
    background-color: var(--var-fill-tertiary-light);
}

.selected {
    background-color: var(--var-fill-quaternary);
    cursor: default;
    color: var(--var-text-accent);
    fill: var(--var-icon-accent);
    &:hover {
        background-color: var(--var-fill-quaternary);
        cursor: default;
    }
    svg {
        fill: var(--var-icon-accent);
    }
}

:global(#main-container[data-open=false]) {
    .menu-item {
        padding: 10px;
    }
}

@import '../../../React/style/mixins.less';

.link {
    text-decoration: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    width: fit-content;
    border-bottom: 1px solid;

   &:hover,
   &:focus {
       cursor: pointer;
       text-decoration: none;
       box-shadow: none;
       outline: none;
   }

   &.disabled {
      border: none;
      cursor: initial;
   }
}

.link.lg {
    .font-body1_small-block;
}

.link.md {
    .font-body2_small-block;
}

.link.sm {
    .font-body3_small-block;
}

.link.xsm {
    .font-caption2;
}

.primary {
    color: var(--var-text-accent);
    border-bottom-color: var(--var-border-accent-light);

    &:hover,
    &:focus,
    &:visited,
    &:active {
        border-bottom: 1px solid;
        color: var(--var-text-accent);
        border-bottom-color: var(--var-border-accent);
    }
}

.secondary {
    color: var(--var-text-primary);
    border-bottom-color: var(--var-border-secondary);

    &:hover,
    &:focus,
    &:visited,
    &:active {
        border-bottom: 1px solid;
        color: var(--var-text-primary);
        border-bottom-color: var(--var-border-primary);
    }
}

.tertiary {
    color: var(--var-text-tertiary);
    border-bottom-color: var(--var-border-secondary);

    &:hover,
    &:focus,
    &:visited,
    &:active {
        border-bottom: 1px solid;
        color: var(--var-text-secondary);
        border-bottom-color: var(--var-border-secondary);
    }
}

.disabled {
    color: var(--var-text-disabled);
    border: none;

    &:hover,
    &:focus {
        cursor: inherit;
        color: var(--var-text-disabled);
        border: none;
    }
}

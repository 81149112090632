@import 'rootReact/style/mixins.less';

.wrapper {
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
}

.error {
    background-color: var(--var-fill-error);
}

.tip {
    background-color: var(--var-fill-accent-vector);
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    flex-shrink: 0;
}

.svg {
    width: 100%;
    height: 100%;
}

.text {
    .font-body2_small-block;
}

.react-wrapper {
    .wrapper();
}

.row {
    .row-flex();
}

.row-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.align-center {
    align-items: center;
}

.xl {
    width: 34px;
    height: 34px;
}

.lg {
    width: 24px;
    height: 24px;
}

.md {
    width: 20px;
    height: 20px;
}

.sm {
    width: 16px;
    height: 16px;
}

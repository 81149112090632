.container {
    display: flex;
    height: 100vh;
    width: 1120px;
    margin: 0 auto;
}

.wrapper {
    width: 100%;
    display: flex;
}

.header {
    position: relative !important;
    z-index: 0!important;
}

.menu-ico {
    position: absolute;
    margin-top: 12px;
    width: 20px;
    height: 10px;
    z-index: 60;
}

.line {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-bottom: 3px;
    transition-duration: .5s;
    transform-origin: 3px 1px 0;
}

.active .line1 {
    transform: rotate(45deg);
}

.active .line2 {
    width: 0;
}

.active .line3 {
    transform: rotate(-45deg);
}

.classic-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 0;
    padding: 0 45px;
    transition-duration: .5s;
    background-color: #c9c9c9
}

.active.classic-menu {
    height: 1000px;
}

.app-wrapper {
    display: block;
    position: relative;
    margin-bottom: 20px;
}

.loader {
    padding-top: 100vh;
}

.wrapper {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(2, 0, 25, 0.5);
    box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
    z-index: 1040;
    overflow: auto;
}

.content {
    position: relative;
    width: 0;
    outline: 0;
    min-height: 100vh;
    background-color: #fff;
    transition: width 1s;
}

.modal-lg {
    animation: ease-in-out 1s forwards modal;
}

.modal-md {
    width: 600px;
}

@keyframes modal {
    from {
        width: 0;
    }
    to {
        max-width: 1400px;
        width: 100%;
    }
}

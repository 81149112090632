@import '../../../React/style/mixins';

.chip {
    display: flex;
    justify-content: center;
    align-items: start;
    white-space: nowrap;
    width: 100%;
}

.hover {
    cursor: pointer;
}

.md {
    .font-body2_small-block;
    padding: 10px 8px;
    border-radius: 6px;
}

.sm {
    .font-body3_small-block;
    padding: 7px 8px;
    border-radius: 4px;
}

.xsm {
    .font-body3_small-block;
    padding: 3px 4px;
    border-radius: 4px;
}

.mint {
    &.hover:hover {
        background-color: var(--var-fill-mint-vector-hover);
    }

    background-color: var(--var-fill-mint-vector);
}

.green {
    &.hover:hover {
        background-color: var(--var-fill-green-vector-hover);
    }

    background-color: var(--var-fill-green-vector);
}

.yellow {
    &.hover:hover {
        background-color: var(--var-fill-yellow-vector-hover);
    }

    background-color: var(--var-fill-yellow-vector);
}

.blue {
    &.hover:hover {
        background-color: var(--var-fill-blue-vector-hover);
    }

    background-color: var(--var-fill-blue-vector);
}

.pink {
    &.hover:hover {
        background-color: var(--var-fill-pink-vector-hover);
    }

    background-color: var(--var-fill-pink-vector);
}

.orange {
    &.hover:hover {
        background-color: var(--var-fill-orange-vector-hover);
    }

    background-color: var(--var-fill-orange-vector);
}

.red {
    &.hover:hover {
        background-color: var(--var-fill-red-vector-hover);
    }

    background-color: var(--var-fill-red-vector);
}

.accent {
    &.hover:hover {
        background-color: var(--var-fill-accent-vector-hover);
    }

    background-color: var(--var-fill-accent-vector);
}

.secondary {
    &.hover:hover {
        background-color: var(--var-fill-tertiary-light);
    }

    background-color: var(--var-fill-secondary);
}

.primary {
    &.hover:hover {
        background-color: var(--var-fill-accent-hover);
    }

    background-color: var(--var-fill-accent);
}

.disabled {
    pointer-events: none;
    cursor: initial;
}

.disabled:not(.primary):not(.secondary) {
    opacity: 0.45;
}

.disabled.secondary,
.disabled.primary {
    background-color: var(--var-fill-disabled);
}

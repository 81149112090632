@import '../../style/mixins.less';

.radiobutton-container {
    display: flex;
    gap: 4px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.circle {
    box-sizing: border-box;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
}

.check-false {
    border-color: var(--var-border-secondary);

    &:hover {
        border-color: var(--var-border-tirtiary);
    }

    &:focus {
        border-color: var(--var-border-accent);
    }
}

.check-true {
    border-color: var(--var-border-accent);
    border-width: 7px;
    background-color: white;

   &:hover {
      border-color: var(--var-border-accent-hover);
   }

   &:focus {
      border-color: var(--var-border-accent-hover);
   }
}

.circle.lg {
   width: 20px;
   height: 20px;
}

.circle.md,
.circle.sm {
   width: 16px;
   height: 16px;
}

.md.check-true,
.sm.check-true {
   border-width: 5.5px;
}

.lg.check-true {
   border-width: 7px;
}

.radio-text {
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--var-text-primary);
}

.radio-text.lg {
    .font-body1_small-block;
}

.radio-text.md {
    .font-body2_small-block;
}

.radio-text.sm {
    .font-body3;
}

.disabled {
   pointer-events: none;
   color: var(--var-text-disabled);
   cursor: default;

   .circle {
      border-color: var(--var-border-disabled);
   }
}

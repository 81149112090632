.graph-text_wrapper {
    display: flex;
    align-items: baseline;
    font-size: 50px;
    line-height: 49px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title {
    color: #000;
    display: inline-block;
    padding: 0;
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 37px;
}

.title:not(.active) {
    cursor: pointer;
}

.disabled {
    cursor: default !important;
}

.none {
    background-color: gray;
}

.default {
    background-color: #5db75c;
}

.red {
    background-color: #d95250;
}

.disabled::after {
    display: none;
}

.title:not(.active):hover::after {
    opacity: 0.3;
}

.square {
    display: block;
    width: 100%;
    height: 100px;
    color: #fff;
    line-height: 100px;
    font-size: 50px;
    text-align: center;
    word-spacing: -5px;
}

.green {
    background-color: green;
}

.rating-change {
    cursor: pointer;
    display: block;
    line-height: 25px;
    font-size: 25px;
    position: absolute;
    padding: 5px 10px;
    top: 5px;
    right: -15px;
    -webkit-box-shadow: -3px 3px 6px 0 rgba(0, 0, 0, .5);
    -moz-box-shadow: -3px 3px 6px 0 rgba(0, 0, 0, 0.5);
    box-shadow: -3px 3px 6px 0 rgba(0, 0, 0, 0.5);
    word-spacing: -5px;
}

.arrow {
    display: inline-block;
    width: 10px;
    height: 17px;
    margin: 0 0 0 3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.active {
    font-weight: 600;
}

.round-wrapper {
    width: 155px;
    height: 155px;
    position: relative;
}

.square-wrapper {
    padding-top: 25px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    margin: 2px;

    &.lg {
        width: 20px;
        height: 20px;
    }

    &.md {
        width: 18px;
        height: 18px;
    }
}

.on-checkbox {
    fill: var(--var-fill-accent);
    cursor: pointer;
    width: 100%;
    height: 100%;

    &:hover,
    &:focus-visible {
        fill: var(--var-fill-accent-hover)
    }
}

.off-checkbox {
    stroke: var(--var-border-secondary);
    stroke-width: 2px;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &:hover,
    &:focus-visible {
        stroke: var(--var-border-tirtiary);
    }
}

.disabled {
    pointer-events: none;
}

.disabled.on-checkbox {
    fill: var(--var-border-disabled);
}

.disabled.off-checkbox {
    stroke: var(--var-fill-disabled);
}

@import "../../../../style/mixins";

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.item:hover {
    background-color: var(--var-fill-secondary);
    cursor: default;
}

.item:hover :global(#ui-on-checkbox) {
    fill: var(--var-border-accent-hover);
}

.item:hover :global(#ui-off-checkbox) {
    stroke: var(--var-border-tirtiary);
}

.item:hover :global(#ui-on-radiobutton) {
    border-color: var(--var-border-accent-hover)
}
.item:hover :global(#ui-off-radiobutton) {
    border-color: var(--var-border-tirtiary);
}

.lg.item {
    padding: 12px 24px 12px 12px;
}

.md.item {
    padding: 10px 24px 10px 12px;
}

.placeholder {
    justify-content: center;
    align-items: center;
    display: flex;
}

.lg.placeholder {
    width: 24px;
    height: 24px;
}

.md.placeholder {
    width: 20px;
    height: 20px;
}

.item-text {
    color: var(--var-text-primary);
    flex-grow: 1;
}

.highlight {
    color: var(--var-text-accent);
}

.lg.item-text {
    .font-body1_small-block;
}

.md.item-text {
    .font-body2_small-block;
}

.disabled {
    pointer-events: none;

    .item-text {
        color: var(--var-text-disabled);
    }
}

@import '../../style/mixins';

.navigation {
    display: flex;
    position: fixed;
    background-color: white;
    flex-direction: column;
    box-sizing: content-box;
    color: var(--var-text-primary);
    border-right: 1px solid var(--var-border-disabled);
    height: 100vh;
    z-index: 11;
    left: 0;
}

.navigation-close {
    width: 60px;
}

.navigation-open {
    width: 240px;
}

.navigation-close.navigation-closed {
    animation: navigation-close .3s ease-in-out forwards;
}

.navigation-open.navigation-opened {
    animation: navigation-open .3s ease-in-out forwards;
}

.header {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 20px 16px 16px;
}

.closed-header {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 18px;
}

.navigation-close .opened-header,
.navigation-open .closed-header {
    opacity: 0;
}

.navigation-open .opened-header,
.navigation-close .close-header{
    opacity: 1;
}

.navigation-opened .opened-header.header-open,
.navigation-closed .closed-header.header-close{
    animation: header-open .3s ease-in-out forwards;
}

.navigation-closed .opened-header.header-close,
.navigation-opened .closed-header.header-open {
    animation: header-close .3s ease-in-out forwards;
}

.ico-arrow-wrapper {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.navigation-close .opened-header .ico-arrow-wrapper {
    pointer-events: none;
}

.navigation-open .closed-header .ico-arrow-wrapper {
    pointer-events: none;
}

.logo-short,
.logo-long {
    cursor: pointer;
}

.logo-long {
    width: 150px;
}

.navigation-close .logo-long {
    pointer-events: none;
}

.navigation-open .logo-short {
    pointer-events: none;
}

.logo-img {
    cursor: pointer;
}

.logo-long .logo-img {
    width: auto;
    min-height: 24px;
    max-height: 48px;
}

.logo-short {
    margin-bottom: 16px;
}

.logo-short .logo-img {
    width: 24px;
    height: 24px;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
}

.ico-arrow {
    width: 100%;
    height: 100%;
    fill: var(--var-icon-tirtiary);
    &:hover {
        fill: var(--var-icon-secondary);
    }
}

.opened-header .ico-arrow {
    transform: rotate(180deg);
}

.company-name {
    .font-body2_small-block;
    color: var(--var-text-primary);
    word-break: break-word;
    transition: .3s width ease-in-out;
    width: 208px;
    overflow: hidden;
}

.company-text {
    width: 208px;
}

.navigation-close .company-name {
    width: 0px;
}

.menu-open {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.navigation-opened .menu-open {
    animation: menu-open .3s ease-in-out forwards;
}

.navigation-closed .menu-close {
    animation: menu-close .3s ease-in-out forwards;
}

.opened-menu {
    padding: 0 8px;
}

.closed-menu {
    padding: 0 10px;
}

.content {
    position: relative;
    display: flex;
}

.opened-text {
    .font-body2_small-block;
    margin-left: 8px;
    opacity: 1;
    white-space: nowrap;
}

.closed-text {
    .font-body2_small-block;
    margin-left: 8px;
    opacity: 0;
    white-space: nowrap;
}

.navigation-opened .text-open {
    animation: text-open .3s ease-in-out forwards;
}

.navigation-closed .text-close {
    animation: text-close .3s ease-in-out forwards;
}

.tooltip {
    position: absolute;
}

.notice {
    position: absolute;
    background-color: var(--var-border-critical);
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.opened-notice {
    left: calc(100% + 8px);
}

.closed-notice {
    left: 14px;
}

.navigation-opened .notice-open {
    animation: notice-open .3s ease-in-out forwards;
}

.navigation-closed .notice-close {
    animation: notice-close .3s ease-in-out forwards;
}

.icon-wrapper {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.icon {
    fill: var(--var-icon-tirtiary);
    width: 100%;
    height: 100%;
}

.footer {
    margin-top: auto;
}

@keyframes navigation-open {
    from {
        width: 60px;
    }

    to {
        width: 240px;
    }
}

@keyframes navigation-close {
    from {
        width: 240px;
    }

    to {
        width: 60px;
    }
}

@keyframes header-open {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes header-close {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes text-open {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes text-close {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes menu-open {
    from {
        padding: 0 10px;
    }

    to {
        padding: 0 8px;
    }
}

@keyframes menu-close {
    from {
        padding: 0 8px;
    }

    to {
        padding: 0 10px;
    }
}

@keyframes notice-open {
    from {
        left: 14px;
    }

    to {
        left: calc(100% + 8px);
    }
}

@keyframes notice-close {
    from {
        left: calc(100% + 8px);
    }

    to {
        left: 14px;
    }
}

@media (max-width: 1409px) {
    .navigation-shadow {
        box-shadow: 0px 4px 16px 0px var(--var-fill-secondary);
    }
}

.common-report {
    width: 220px;
    text-wrap: initial;
}

.common-report div:not(:last-child) {
    margin-bottom: 10px;
}

.bottom-popup {
    top: 25px;
}

.bottom-popup-text {
    width: 225px;
}
